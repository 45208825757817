<script>
import store from "../Store.js";
import BookViewPath from './BookViewPath.vue';
import BookViewDefault from './BookViewDefault-deprecated.vue';

export default {
    name: 'BookView',
    emits: [
        'goHome',
        'goUpViewLevel',
    ],
    components: {
        BookViewPath,
        BookViewDefault,
    },
    props: {
        translation: String,
        book: String,
    },
    computed: {
        bookData() {
            return store.bookData;
        },
        positions() {
            if (!!this.bookData) {
                if (!!this.bookData.positions) {
                    return this.bookData.positions.points;
                } else {
                    return [];
                }
            } else {
                return null;
            }
        },
        scale() {
            if (!!this.bookData) {
                if (!!this.bookData.positions) {
                    return this.bookData.positions.scale;
                } else {
                    return 1;
                }
            } else {
                return null;
            }
        }
    },
    created() {
        store.selectedBook = this.book;
        store.fetchBookData();
    },
}
</script>

<template>
    <div>
        <BookViewPath
            v-if="positions !== null"
            @go-home="$emit('goHome')"
            @go-up-view-level="$emit('goUpViewLevel')"
            :positions="positions"
            :scale="scale"
            :data="bookData"
            />
        <BookViewDefault
            v-else
            @go-home="$emit('goHome')"
            @go-up-view-level="$emit('goUpViewLevel')"
            :data="bookData"
            />
    </div>
</template>

<style scoped>
</style>