<script>
import store from "../Store.js";
import Footer from "./Footer.vue";
import Header from "./Header.vue";

export default {
    name: 'BookViewDefault',
    components: {
        Footer,
        Header,
    },
    emits: [
        'goHome',
        'goUpViewLevel',
    ],
    data() {
        return {
            clickCoordinates: null,
            panZ: null,
        }
    },
    computed: {
        title() {
            return store.title;
        },
        groupClass() {
            return store.groupClass();
        },
        loaded() {
            return store.bookDataLoaded;
        },
    },
    methods: {
        bookData() {
            return store.bookData.text;
        },
        getChapterArray() {
            if (this.bookData() === null) {
                return [];
            }
            const maxChapter = Object.keys(this.bookData()).length;
            let array = [...Array(maxChapter).keys()];
            return array.map(item => ++item);
        },
        openChapter(event) {
            store.selectedChapter = parseInt(event.target.id.split('-').at(-1));
            store.currentViewIndex++;
        },
        clickChapterStart(event) {
            this.clickCoordinates = {x: event.x, y: event.y};
        },
        clickChapterEnd(event) {
            const MAX_DISTANCE = 10;
            const dX = event.x-this.clickCoordinates.x;
            const dY = event.y-this.clickCoordinates.y;
            const distance = Math.sqrt(dX**2 + dY**2);
            if (distance < MAX_DISTANCE) {
                this.openChapter(event);
            }
        },
        _setUpPanZForPortrait() {
            this.panZ.zoomTo(0.4);
            this.panZ.panTo(0.43, 0.4, false, false);
        },
        _setUpPanZForLandscape() {
            this.panZ.zoomTo(0.75);
            this.panZ.panTo(0.46, 0.45, false, false);
        },
        setUpPanZ(zoomableElement) {
            this.panZ = store.newPanZ(this.$refs.bounding);
            this.panZ.init(zoomableElement);
            if (store.isLandscape()) {
                this._setUpPanZForLandscape();
            } else {
                this._setUpPanZForPortrait();
            }
        },
        resize() {
            this.panZ.reset();
            if (store.isLandscape()) {
                this.panZ.zoomTo(0.75, 0.16, 0.2, false, true);
            } else {
                this.panZ.zoomTo(0.4);
                this.panZ.panTo(1.1, 1., false, true);
            }
        },
    },
    mounted() {
        this.setUpPanZ(this.$refs.zoomable);
    },
}
</script>

<template>
    <div class="app">
        <Header :has-header-title="true" :has-header-number="false"></Header>
        <main class="body" ref="bounding">
            <div id="body__content--book" ref="zoomable">
                <div v-if="loaded" class="body__content body__content--book">
                    <div v-for="number in getChapterArray()" :key="number" class="grid__item grid__item--book">
                        <button
                            v-bind:id="`chapter_tile-${number}`"
                            @pointerdown="clickChapterStart"
                            @pointerup="clickChapterEnd"
                            :class="`grid__item--group-${groupClass}`"
                            >
                            {{ number }}
                        </button>
                    </div>
                </div>
                <div v-else>loading...</div>
            </div>
        </main>
        <Footer
            :has-back-button="false"
            :has-forward-button="false"
            :has-home-and-up-buttons="true"
            @go-home="$emit('goHome')"
            @go-up-view-level="$emit('goUpViewLevel')"
            @resize="resize"
            >
        </Footer>
    </div>
</template>

<style scoped>
.body__content--book {
    display: grid;
    grid-template-rows: repeat(15, 1fr);
    grid-template-columns: repeat(10, 1fr);
    column-gap: 2rem;
    row-gap: 4rem;
    font-size: 0.8rem;
}
@media screen and (orientation: landscape) {
    .body__content--book {
        font-size: 1.5rem;
        row-gap: 2rem;
        column-gap: 1rem;
    }
}
.grid__item--book {
    height: 3.5rem;
    width: 3.5rem;
    margin: auto;
}
</style>