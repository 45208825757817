import { reactive } from "vue";
import panZ from '@thesoulfresh/pan-z';


import { API_URL, GROUP, SCRIPTURES } from './constants.js'

const HEBREW_BOOKS_AMOUNT = 38;

export default reactive({
    selectedChapter: null,
    selectedBook: null,
    bookData: {text: null},
    metaData: null,
    translation: null,
    panZ: panZ,
    chapterTextScale: 1,
    bibleMapName: 'default',
    newPanZ(boundingElement) {
        return new this.panZ({
            zoomSpeed: 0.8,
            bounds: 0,
            boundingElement: boundingElement,
            maxZoom: 4*4,  // This is 4 times the default.
        });
    },
    isLandscape() {
        return window.innerWidth > window.innerHeight;
    
    },
    params: new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
        set: (searchParams, prop, value) => searchParams.set(prop, value),
    }),
    get title() {
        if (!this.metaData || !this.bibleMapName || !this.selectedBook) {
            return null;
        }
        var index = SCRIPTURES[this.selectedBook] - 1;
        if (index <= HEBREW_BOOKS_AMOUNT) {
            return Object.entries(this.metaData[this.bibleMapName].hb[index])[0][1].name;
        } else {
            index = index - HEBREW_BOOKS_AMOUNT - 1;
            return Object.entries(this.metaData[this.bibleMapName].gk[index])[0][1].name;
        }
    },
    get code() {
        var index = SCRIPTURES[this.selectedBook] - 1;
        if (index <= HEBREW_BOOKS_AMOUNT) {
            return Object.entries(this.metaData[this.bibleMapName].hb[index])[0][1].code;
        } else {
            index = index - HEBREW_BOOKS_AMOUNT - 1;
            return Object.entries(this.metaData[this.bibleMapName].gk[index])[0][1].code;
        }
    },
    get opacity() {
        return this.metaData.opacity;
    },
    get availableBibleMaps() {
        const allMetaKeys = Object.keys(this.metaData);
        let available = [];
        allMetaKeys.forEach(key => {
            if (key !== 'default' && key !== 'opacity' && key !== 'translations') {
                available.push(key);
            }
        });
        return available;
    },
    get availableTranslations() {
        if (!!this.metaData) {
            return this.metaData.translations;
        } else {
            return [];
        }
    },
    groupClass() {
        if (!this.metaData || !this.bibleMapName || !this.selectedBook) {
            return null;
        }
        var index = SCRIPTURES[this.selectedBook] - 1;
        if (index <= HEBREW_BOOKS_AMOUNT) {
            const groupStr = Object.entries(this.metaData[this.bibleMapName].hb[index])[0][1].group;
            return this.groupNumber(groupStr);
        } else {
            index = index - HEBREW_BOOKS_AMOUNT - 1;
            const groupStr = Object.entries(this.metaData[this.bibleMapName].gk[index])[0][1].group;
            return this.groupNumber(groupStr);
        }
    },
    groupNumber(group) {
        return GROUP[group];
    },
    async fetchBookData() {
        const cachedBibleData = JSON.parse(window.localStorage.getItem(this.translation));
        if (!!cachedBibleData) {
            const cachedBookData = cachedBibleData[this.selectedBook];
            if (!!cachedBookData) {
                this.bookData = cachedBookData;
            } else {
                const response = await fetch(`${this.API_URL}getBook/${this.translation}/${this.selectedBook}/`).then((response) => response.json());
                if (response.status !== 'ok') {
                    console.log(`Server error: ${response.response}`);
                } else {
                    this.bookData = response.response;
                    cachedBibleData[this.selectedBook] = this.bookData;
                    window.localStorage.setItem([this.translation], JSON.stringify(cachedBibleData));
                }
            }
        } else {
            const response = await fetch(`${this.API_URL}getBook/${this.translation}/${this.selectedBook}/`).then((response) => response.json());
            if (response.status !== 'ok') {
                console.log(`Server error: ${response.response}`);
            } else {
                this.bookData = response.response;
                window.localStorage.setItem([this.translation], JSON.stringify({[this.selectedBook]: this.bookData}));
            }
        }
        this.bookDataLoaded = true;
    },
    async fetchMetaData() {
        const response = await fetch(`${this.API_URL}getBookTiles/`).then((response) => response.json());
            if (response.status !== 'ok') {
                console.log(`Server error: ${response.response}`);
            } else {
                this.metaData = response.response;
            }
        this.translation = this.availableTranslations[0].code;
    },
    API_URL,
})