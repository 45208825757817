export const GROUP = Object.freeze({
	'Torah': 1,
  'Early': 2,
  'Later': 3,
  'Poetic': 4,
  'Festival': 5,
  'Restoration': 6,
  'Matthew': 7,
  'Mark': 8,
  'Luke': 9,
  'John': 10,
  'Letters': 11,
  'Paul': 12,
  'Minor': 13,
});

export const API_URL = import.meta.env.VITE_API_URL;

export const SCRIPTURES = Object.freeze({
  'gen': 1,
  'exo': 2,
  'lev': 3,
  'num': 4,
  'deu': 5,
  'jos': 6,
  'jdg': 7,
  'rth': 8,
  '1sa': 9,
  '2sa': 10,
  '1ki': 11,
  '2ki': 12,
  '1ch': 13,
  '2ch': 14,
  'ezr': 15,
  'neh': 16,
  'est': 17,
  'job': 18,
  'psa': 19,
  'pro': 20,
  'ecc': 21,
  'sos': 22,
  'isa': 23,
  'jer': 24,
  'lam': 25,
  'eze': 26,
  'dan': 27,
  'hos': 28,
  'joe': 29,
  'amo': 30,
  'oba': 31,
  'jon': 32,
  'mic': 33,
  'nah': 34,
  'hab': 35,
  'zep': 36,
  'hag': 37,
  'zec': 38,
  'mal': 39,
  'mat': 40,
  'mar': 41,
  'luk': 42,
  'jhn': 43,
  'act': 44,
  'rom': 45,
  '1co': 46,
  '2co': 47,
  'gal': 48,
  'eph': 49,
  'php': 50,
  'col': 51,
  '1th': 52,
  '2th': 53,
  '1ti': 54,
  '2ti': 55,
  'tit': 56,
  'phm': 57,
  'heb': 58,
  'jas': 59,
  '1pe': 60,
  '2pe': 61,
  '1jn': 62,
  '2jn': 63,
  '3jn': 64,
  'jud': 65,
  'rev': 66,
});
