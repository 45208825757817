<script>
import { nextTick } from "vue";
import store from "../Store.js";
import Footer from "./Footer.vue";
import Header from "./Header.vue";
import ChapterButton from "./ChapterButton.vue";
import mixin from "../mixin.js";

export default {
    name: 'BookViewPath',
    components: {
        Footer,
        Header,
        ChapterButton,
    },
    mixins: [
        mixin,
    ],
    emits: [
        'goHome',
        'goUpViewLevel',
    ],
    props: {
        positions: Array,
        scale: Number,
        data: Object,
    },
    data() {
        return {
            clickCoordinates: null,   
            panZ: null,
        }
    },
    computed: {
        title() {
            return store.title;
        },
        bookData() {
            return store.bookData;
        },
        bookMapUrl() {
            let imagePath = '';
            if (!!this.data.bookMapImage) {
                imagePath = this.data.bookMapImage;
            }
            const url = new URL(`${import.meta.env.VITE_MAP_IMAGE_URL}${imagePath}`).href;
            const timestamp = Date.now();
            return `${url}?${timestamp}`;
        },
    },
    methods: {
        getChapterArray() {
            if (this.bookData.text === null) {
                return [];
            }
            const maxChapter = Object.keys(this.bookData.text).length;
            let array = [...Array(maxChapter).keys()];
            return array.map(item => ++item);
        },
        openChapter(event) {
            const selectedChapter = parseInt(event.target.id.split('-').at(-1));
            store.selectedChapter = selectedChapter;
            this.updateRoute('chapter', { chapter: selectedChapter });
        },
        clickChapterStart(event) {
            this.clickCoordinates = {x: event.x, y: event.y};
        },
        clickChapterEnd(event) {
            const MAX_DISTANCE = 10;
            const dX = event.x-this.clickCoordinates.x;
            const dY = event.y-this.clickCoordinates.y;
            const distance = Math.sqrt(dX**2 + dY**2);
            if (distance < MAX_DISTANCE) {
                this.openChapter(event);
            }
        },
        setUpPanZ(zoomableElement) {
            this.panZ = store.newPanZ(this.$refs.bounding);
            this.panZ.init(zoomableElement);
            // The reset method is called after this as a hack to try to force the zoomable region to center in one motion.
            nextTick();
            this.panZ.reset();
        },
        resize() {
            // The reset method is called twice as a hack to try to force the zoomable region to center in one motion.
            this.panZ.reset();
            nextTick();
            this.panZ.reset();
        },
    },
    mounted() {
        this.setUpPanZ(this.$refs.zoomable);
    },
}
</script>

<template>
    <div class="app">
        <Header :has-header-title="true" :has-header-number="false"></Header>
        <main class="body" ref="bounding">
            <div id="body__content--book" ref="zoomable">
                <div v-if="!!data" class="body__content body__content--book">
                    <img :src="bookMapUrl"/>
                    <ChapterButton
                        v-for="number in getChapterArray()"
                        :key="number"
                        :number="number"
                        :svg-position="positions[number-1]"
                        :scale="scale"
                        @startClick="clickChapterStart"
                        @endClick="clickChapterEnd"
                    >
                    </ChapterButton>
                </div>
                <div v-else>loading...</div>
            </div>
        </main>
        <Footer
            :has-back-button="false"
            :has-forward-button="false"
            :has-home-and-up-buttons="true"
            @go-home="$emit('goHome')"
            @go-up-view-level="$emit('goUpViewLevel')"
            @resize="resize"
            >
        </Footer>
    </div>
</template>

<style scoped>
.body {
    position: relative;
    top: 15vh;
}
.body__content--book {
    font-size: 0.8rem;
    position: relative;
    left: 0px;
    top: 0px;
    padding: 0;
}
@media screen and (orientation: landscape) {
    .body__content--book {
        font-size: 1.5rem;
    }
}
img {
    width: 100%;
    height: auto;
}
</style>