<script>
import store from "../Store.js";

export default {
    name: 'SettingsDrawer',
    computed: {
        translations() {
            return store.availableTranslations;
        },
        layoutVersions() {
            return store.availableBibleMaps;
        },
    },
    methods: {
        toTitleCase(text) {
            return text.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                return letter.toUpperCase();
            });
        },
        selectTranslation(event) {
            store.translation = event.target.value;
            const urlPath = this.$router.currentRoute.value.path.replace(/^\/|\/$/, '').replace(/^\/|\/$/, '');
            const urlParts = urlPath.split('/');
            switch (urlParts.length) {
                case 1: // Bible view
                    this.$router.replace({ path: `/${store.translation}` })
                    break;
                case 2: // Book view
                    this.updateRoute('book', { translation: store.translation, book: store.selectedBook });
                    break;
                case 3: // Chapter view
                    this.updateRoute('chapter', { translation: store.translation, book: store.selectedBook, chapter: store.selectedChapter });
                    break;
            }
        },
        refreshRoute() {
            const currentPath = this.$router.currentRoute.value.path;
            this.$router.replace('/dummy').then(() => this.$router.replace(currentPath));
        },
        selectVersion(event) {
            store.bibleMapName = event.target.value;
            this.refreshRoute();
        },
    },
    mounted() {
        const settingsDrawer = this.$refs.settingsDrawer;
        settingsDrawer.animate([
            {
                transform: 'translateY(-50px)',
                opacity: 0,
            },
            {
                transform: 'translateY(0px)',
                opacity: 1,
            },
        ], {
            duration: 250,
            iterations: 1,
        });
    },
}
</script>

<template>
    <div ref="settingsDrawer" style="background-color: #fff; z-index: 2;">
        <div style="display: flex; justify-content: space-around; margin-left: 4rem; margin-top: 1rem;">
            <div style="display: flex; flex-direction: column;">
                <label style="margin-bottom: 0.5rem;">Translation</label>
                <select @change="selectTranslation">
                    <option
                        v-for="translation of translations"
                        :value="translation.code"
                        >
                            {{ translation.code.toUpperCase() }}
                    </option>
                </select>
            </div>
            <div style="display: flex; flex-direction: column;">
                <label style="margin-bottom: 0.5rem;">Layout</label>
                <select @change="selectVersion">
                    <option
                        v-for="layoutVersion in layoutVersions"
                        :value="layoutVersion"
                        >
                            {{ toTitleCase(layoutVersion) }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>