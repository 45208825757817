<script>
import store from "../Store.js";
import RefreshButton from "./RefreshButton.vue";
import SettingsButton from "./SettingsButton.vue";
import SettingsDrawer from "./SettingsDrawer.vue";

export default {
    name: 'Header',
    components: {
      RefreshButton,
      SettingsButton,
      SettingsDrawer,
    },
    props: {
        hasHeaderTitle: Boolean,
        hasHeaderNumber: Boolean,
    },
    data() {
        return {
          drawerIsOpen: false,
        }
    },
    computed: {
        titleBook() {
            return store.title;
        },
        titleChapter() {
            return store.selectedChapter;
        },
        groupClass() {
            return store.groupClass();
        },
        isBibleView() {
            const urlPath = this.$router.currentRoute.value.path.replace(/^\/|\/$/, '').replace(/^\/|\/$/, '');
            const urlParts = urlPath.split('/');
            return urlParts.length === 1;
        },
    },
    methods: {
        toggleDrawer() {
            this.drawerIsOpen = !this.drawerIsOpen;
        },
    },
}
</script>

<template>
    <header class="header" id="header">
        <RefreshButton />
        <SettingsButton
          v-if="isBibleView"
          @toggle-drawer="toggleDrawer"
          />
        <SettingsDrawer
          v-if="drawerIsOpen"
          />
        <h1 v-if="hasHeaderTitle" class="header__title" :class="`header--group-${groupClass}`">{{ titleBook }}</h1>
        <h2 v-if="hasHeaderNumber" class="header__number" :class="`header__number--group-${groupClass}`">{{ titleChapter }}</h2>
    </header>
</template>

<style scoped>
.header {
  height: 15vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  display: grid;
  background-color: var(--background);
}
.header__title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}
@media screen and (orientation: landscape) {
  .header__title {
    font-size: 2rem; 
  }
}
.header__number {
  font-size: 1.2rem;
  border-radius: 50%;
  margin: auto;
  height: 32.5px;
  width: 40px;
  padding-top: 7.5px;
  text-align: center;
  color: #fff;
}
@media screen and (orientation: landscape) {
  .header__number {
    height: 35px;
    width: 40px;
    padding-top: 5px;
  }
}
</style>