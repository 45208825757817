<script>
import store from "../Store.js";

const RAW_SIZE = {x: 200, y: 100};

export default {
    name: 'BookButton',
    emits: [
        'startClick',
        'endClick',
    ],
    props: {
        abbreviation: String,
        name: String,
        group: String,
        rawPosition: Object,
        rawSize: Object,
    },
    data() {
        return {
            mediumFontSize: true,
            largeFontSize: true,
        }
    },
    computed: {
        domPosition() {
            const viewportWidth = window.innerWidth;
            const viewportHeight = viewportWidth * (RAW_SIZE.y/RAW_SIZE.x);
            const x = this.rawPosition.x / RAW_SIZE.x * viewportWidth;
            const y = this.rawPosition.y / RAW_SIZE.y * viewportHeight;
            return { x, y };
        },
        domSize() {
            const viewportWidth = window.innerWidth;
            const viewportHeight = viewportWidth * (RAW_SIZE.y/RAW_SIZE.x);
            const width = this.rawSize.width / RAW_SIZE.x * viewportWidth;
            const height = this.rawSize.height / RAW_SIZE.y * viewportHeight;
            return { width, height };
        },
        groupNumber() {
            return store.groupNumber(this.group);
        },
        opacity() {
            return store.opacity;
        },
    },
    methods: {
        isOverflowing(element) {
            const currentOverflow = element.style.overflow;
            if (!currentOverflow || currentOverflow === "visible")
                element.style.overflow = "hidden";
            const isWidthOverflowing = element.clientWidth < element.scrollWidth;
            const isHeigthOverflowing = element.clientHeight < element.scrollHeight;
            const isOverflowing = isWidthOverflowing || isHeigthOverflowing;
            element.style.overflow = currentOverflow;
            return isOverflowing;
        },
        decrementTextSize() {
            const textElement = this.$refs.button;
            const isOverflowing = this.isOverflowing(textElement);
            if (isOverflowing && this.largeFontSize) {
                this.largeFontSize = false;
            }
            else if (isOverflowing && !this.largeFontSize) {
                this.mediumFontSize = false;
            }
        },
    },
    mounted() {
        // The text of the book tiles should start as large as possible
        // and then be made smaller until they fit within the width and height of their box.
        // First, the size is decremented from large to medium. And then after recalculating the overflow,
        // can be decremented from medium to small.
        setTimeout(this.decrementTextSize, 100);
        setTimeout(this.decrementTextSize, 500);
    },
}
</script>

<template>
    <button
        ref="button"
        v-bind:id="`book_tile-${abbreviation}`"
        @pointerdown="$emit('startClick', $event)"
        @pointerup="$emit('endClick', $event)"
        class="grid__tile"
        :class="[`grid__item--group-${groupNumber}`, {mediumFont: mediumFontSize}, {largeFont: largeFontSize}]"
        :style="`position: absolute; left: ${domPosition.x}px; top: ${domPosition.y}px; width: ${domSize.width}px; height: ${domSize.height}px; opacity: ${opacity};`"
        >{{ name }}
    </button>
</template>

<style scoped>
.grid__tile {
  place-self: center;
  font-size: 0.1rem;
}
@media screen and (orientation: landscape) {
    .grid__tile {
        font-size: 0.33rem;
}
}
.mediumFont {
    font-size: 0.2rem;
}
@media screen and (orientation: landscape) {
    .mediumFont {
        font-size: 0.8rem;
}
}
.largeFont {
    font-size: 0.5rem;
}
@media screen and (orientation: landscape) {
    .largeFont {
        font-size: 1.0rem;
}
}
</style>