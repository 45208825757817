<script>
import store from "../Store.js";

const SVG_MAX = {x: 2000, y: 1500};

export default {
    name: 'ChapterButton',
    emits: [
        'startClick',
        'endClick',
    ],
    props: {
        number: Number,
        svgPosition: Object,
        scale: Number,
    },
    computed: {
        groupClass() {
            return store.groupClass();
        },
        portraitDefault() {
            return {
                height: 5,  // vw
                width: 5,  // vw
                fontSize: 0.8,  // rem
            };
        },
        landscapeDefault() {
            return {
                height: 3.5,  // vw
                width: 3.5,  // vw
                fontSize: 1.5,  // rem
            };
        },
        size() {
            let defaultSize;
            if (store.isLandscape()) {
                defaultSize = this.landscapeDefault;
            } else {
                defaultSize = this.portraitDefault;
            }
            const height = defaultSize.height * this.scale;
            const width = defaultSize.width * this.scale;
            const fontSize = defaultSize.fontSize * this.scale;
            return {
                height,
                width,
                fontSize,
            }
        },
        domPosition() {
            const viewportWidth = window.innerWidth;
            // The viewport height should be relative to the viewport width and the SVG aspect ratio.
            const viewportHeight = viewportWidth * (SVG_MAX.y/SVG_MAX.x);
            const buttonRadius = this.size.width*(viewportWidth/100) / 2;
            const x = this.svgPosition.x / SVG_MAX.x * viewportWidth - buttonRadius;
            const y = this.svgPosition.y / SVG_MAX.y * viewportHeight - buttonRadius;
            return {
                x: x,
                y: y,
            }
        },
        opacity() {
            return store.opacity;
        },
    },
}
</script>

<template>
    <button
        v-bind:id="`chapter_tile-${number}`"
        @pointerdown="$emit('startClick', $event)"
        @pointerup="$emit('endClick', $event)"
        class="grid__item--book"
        :class="`grid__item--group-${groupClass}`"
        :style="`position: absolute; left: ${domPosition.x}px; top: ${domPosition.y}px; height: ${size.height}vw; width: ${size.width}vw; font-size: ${size.fontSize}rem; opacity: ${opacity};`"
        >
        {{ number }}
    </button>
</template>

<style scoped>
.grid__item--book {
    place-self: center;
    border-radius: 50%;
}
</style>