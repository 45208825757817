<script>
import ParagraphDefault from "./ParagraphDefault.vue";
import ParagraphSubheading from "./ParagraphSubheading.vue";

export default {
    name: 'Pararaph',
    components: {
        ParagraphDefault,
        ParagraphSubheading,
    },
    props: {
        paragraph: [Object, Array],
        hasSubheadings: Boolean,
        groupClass: Number,
        baseScale: Number,
    },
    methods: {
        isSubheading() {
            return Object.hasOwn(this.paragraph, 'subheading');
        },
    },
}
</script>

<template>
    <div>
        <ParagraphSubheading
            v-if="isSubheading()"
            :has-subheadings="hasSubheadings"
            :paragraph="paragraph"
            :group-class="groupClass"
            />
        <ParagraphDefault
            v-else
            :has-subheadings="hasSubheadings"
            :paragraph="paragraph"
            :group-class="groupClass"
            :base-scale="baseScale"
            />
    </div>
</template>

<style scoped></style>