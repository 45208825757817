<script>
import { nextTick } from "vue";
import store from "../Store.js";
import Footer from "./Footer.vue";
import Header from "./Header.vue";
import Paragraph from "./Paragraph.vue";
import ParagraphDefault from "./ParagraphDefault.vue";

export default {
    name: 'ChapterViewDefault',
    components: {
        Footer,
        Header,
        Paragraph,
        ParagraphDefault,
    },
    props: {
        paragraphs: Array,
        hasSubheadings: Boolean,
    },
    data() {
        return {
            panZ: null,
        }
    },
    computed: {
        titleBook() {
            return store.title;
        },
        titleChapter() {
            return store.selectedChapter;
        },
        groupClass() {
            return store.groupClass();
        },
        bookData() {
            return store.bookData;
        },
    },
    methods: {
        setUpPanZ(zoomableElement) {
            this.panZ = store.newPanZ(this.$refs.bounding);
            this.panZ.init(zoomableElement);
            // The reset method is called after this as a hack to try to force the zoomable region to center in one motion.
            nextTick();
            this.panZ.reset();
        },
        resize() {
            // The reset method is called twice as a hack to try to force the zoomable region to center in one motion.
            this.panZ.reset();
            nextTick();
            store.chapterTextScale = 1.0;
            this.panZ.reset();
        },
        scaleMilestones() {
            const transformText = this.$refs.zoomable.style.transform;
            const match = transformText.match(/scale\((\d+(\.\d+)?)\)/);
            if (!!match) {
                store.chapterTextScale = parseFloat(match[1]);
            }
        },
        scaleMilestonesForTouch(event) {
            if (event.touches.length === 2) {
                this.scaleMilestones();
            }
        },
    },
    mounted() {
        this.setUpPanZ(this.$refs.zoomable);
    },
}
</script>

<template>
    <div class="app">
        <Header :has-header-title="true" :has-header-number="true"></Header>
        <main v-if="bookData!==null" class="body" ref="bounding">
            <div ref="zoomable" @wheel.prevent="scaleMilestones" @touchmove.prevent="scaleMilestonesForTouch">
                <div v-if="bookData!==null && hasSubheadings===true" class="body__content body__content--chapter">
                    <div id="chapter-text">
                        <Paragraph
                            v-for="paragraph, i in paragraphs"
                            :key="i"
                            :paragraph="paragraph"
                            :has-subheadings="hasSubheadings"
                            :group-class="groupClass"
                            :base-scale="1.0"
                        />
                    </div>
                </div>
                <div v-else-if="bookData!==null && hasSubheadings===false" class="body__content body__content--chapter">
                    <div id="chapter-text">
                        <div v-for="paragraph, i in paragraphs" :key="i">
                            <ParagraphDefault
                                :has-subheadings="hasSubheadings"
                                :paragraph="paragraph"
                                :group-class="groupClass"
                                :base-scale="1.0"
                                />
                        </div>
                    </div>
                </div>
                <div v-else>error</div>
            </div>
        </main>
        <Footer
            :has-back-button="true"
            :has-forward-button="true"
            :has-home-and-up-buttons="true"
            @go-home="$emit('goHome')"
            @go-up-view-level="$emit('goUpViewLevel')"
            @change-chapter="$emit('changeChapter'); resize()"
            @resize="resize"
            >
        </Footer>
    </div>
</template>

<style scoped>
.body {
    position: relative;
    top: 15vh;
}
div.body__content--chapter {
    padding: 1rem 0rem 1rem 0rem;
}
@media screen and (orientation: landscape) {
    div.body__content--chapter {
        font-size: 1.5rem;
    }
}
div.verse {
    display: inline;
}
.verse-text {
    font-family: var(--secondary-font-family);
}
.verse-number {
    font-family: var(--primary-font-family);
}
.paragraph {
    text-indent: 1rem;
    padding-bottom: 0.5rem;
}
div#chapter-text {
    max-width: 30ch;
    margin: auto;
    width: 100%;
    height: 100%
}
.chapter-subheading {
    font-size: 1.2rem;
    text-align: center;
    padding: 0.5rem;
    color: #a3a8ac;
}
@media screen and (orientation: landscape) {
    .chapter-subheading {
        font-size: 1.5rem;
    }
}
</style>