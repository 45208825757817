<script>
import store from "../Store.js";
import ChapterViewImages from "./ChapterViewImages.vue";
import ChapterViewDefault from "./ChapterViewDefault.vue";

export default {
    name: 'ChapterView',
    components: {
        ChapterViewImages,
        ChapterViewDefault,
    },
    props: {
        translation: String,
        book: String,
        chapter: Number,
    },
    computed: {
        hasImages() {
            const verseImagesForBook = store.bookData.verseImages;
            if (!!verseImagesForBook) {
                const verseImagesForChapter = verseImagesForBook[store.selectedChapter];
                if (!!verseImagesForChapter) {
                    return true;
                }
            }
            return false;
        },
        bookData() {
            return store.bookData;
        },
    },
    data() {
        return {
            hasSubheadings: false,
        };
    },
    methods: {
        changeChapter() {}, // This method is intentionally empty.
        paragraphs() {
            if (!store.selectedChapter) {
                return [];
            }
            if (!!this.bookData.subheadings) {
                this.hasSubheadings = true;
                const chapterOutput = [];
                const subheadings = this.bookData.subheadings[store.selectedChapter];
                const paragraphs = this.bookData.text[store.selectedChapter];
                for (const paragraph of paragraphs) {
                    let paragraphOutput = [];
                    for (const verse of Object.entries(paragraph)) {
                        const [number, text] = verse;
                        if (number in subheadings) {
                            if (paragraphOutput.length > 0) {
                                chapterOutput.push(paragraphOutput);
                                paragraphOutput = [];
                            }
                            chapterOutput.push({
                                subheading: subheadings[number],
                            });
                            paragraphOutput.push({
                                number,
                                text,
                            });
                        } else {
                            paragraphOutput.push({
                                number,
                                text,
                            });
                        }
                    }
                    chapterOutput.push(paragraphOutput);
                }
                return chapterOutput;
            } else {
                return this.bookData.text[store.selectedChapter];
            }
        },
    },
    created() {
        store.selectedChapter = this.chapter;
        if (!!this.bookData) {
            store.selectedBook = this.book;
            store.fetchBookData();
        }
    },
}
</script>

<template>
    <div>
        <ChapterViewImages
            v-if="hasImages"
            :paragraphs="paragraphs()"
            :hasSubheadings="hasSubheadings"
            @go-home="$emit('goHome')"
            @go-up-view-level="$emit('goUpViewLevel')"
            @change-chapter="changeChapter"
            />
        <ChapterViewDefault
            v-else
            :paragraphs="paragraphs()"
            :hasSubheadings="hasSubheadings"
            @go-home="$emit('goHome')"
            @go-up-view-level="$emit('goUpViewLevel')"
            @change-chapter="changeChapter"
            />
    </div>
</template>