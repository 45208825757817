<script>
import { nextTick } from "vue";
import store from "../Store.js";
import Footer from "./Footer.vue";
import Header from "./Header.vue";
import Paragraph from "./Paragraph.vue";
import ParagraphDefault from "./ParagraphDefault.vue";

export default {
    name: 'ChapterViewImages',
    components: {
        Footer,
        Header,
        Paragraph,
        ParagraphDefault,
    },
    props: {
        paragraphs: Array,
        hasSubheadings: Boolean,
    },
    data() {
        return {
            imagesLoaded: false,
            panZ: null,
        }
    },
    computed: {
        titleBook() {
            return store.title;
        },
        titleChapter() {
            return store.selectedChapter;
        },
        groupClass() {
            return store.groupClass();
        },
        bookData() {
            return store.bookData;
        },
        images() {
            const images = [];
            for (const [number, data] of Object.entries(this.bookData.verseImages[store.selectedChapter])) {
                const newData = {
                    ...data,
                    number: number,
                    offset: 0,
                };
                images.push(newData);
            }
            return images;
        },
        leftImages() {
            const leftImages = this.images.filter(image => image.is_left);
            return leftImages;
        },
        rightImages() {
            const rightImages = this.images.filter(image => !image.is_left);
            return rightImages;
        },
    },
    methods: {
        getUrl(urlPath) {
            const url = new URL(`${import.meta.env.VITE_MAP_IMAGE_URL}${urlPath}`).href;
            const timestamp = Date.now();
            return `${url}?${timestamp}`;
        },
        renderImages() {
            if (!!this.images) {
                const header = document.querySelector('#header');
                const headerHeight = header.getBoundingClientRect().height;
                const OFFSET = 25; // This is to make the images align with their verse numbers.
                for (const image of this.images) {
                    const element = document.querySelector(`#verse-${image.number}`);
                    image.offset = element.getBoundingClientRect().top-headerHeight-OFFSET;
                }
            }
        },
        setUpPanZ(zoomableElement) {
            this.panZ = store.newPanZ(this.$refs.bounding);
            this.panZ.init(zoomableElement);
            // The reset method is called after this as a hack to try to force the zoomable region to center in one motion.
            nextTick();
            this.panZ.reset();
        },
        resize() {
            // The reset method is called twice as a hack to try to force the zoomable region to center in one motion.
            this.panZ.reset();
            nextTick();
            store.chapterTextScale = 1.0;
            this.panZ.reset();
        },
        scaleMilestones() {
            const transformText = this.$refs.zoomable.style.transform;
            const match = transformText.match(/scale\((\d+(\.\d+)?)\)/);
            if (!!match) {
                store.chapterTextScale = parseFloat(match[1]);
            }
        },
        scaleMilestonesForTouch(event) {
            if (event.touches.length === 2) {
                this.scaleMilestones();
            }
        },
    },
    mounted() {
        this.setUpPanZ(this.$refs.zoomable);
        // The timeout can't be less than 200ms or else the images render above the verse numbers.
        const TIMEOUT = 200;
        setTimeout(() => {
            this.renderImages();
            this.imagesLoaded = true;
        }, TIMEOUT);
    },
}
</script>

<template>
    <div class="app">
        <Header :has-header-title="true" :has-header-number="true"></Header>
        <main v-if="bookData!==null" class="body" ref="bounding">
            <div ref="zoomable" @wheel.prevent="scaleMilestones" @touchmove.prevent="scaleMilestonesForTouch">
                <div v-if="bookData!==null && hasSubheadings===true" class="body__content body__content--chapter">
                    <div id="chapter-text">
                        <div class="chapter-text__with-images">
                            <div class="image-column">
                                <div v-if="imagesLoaded">
                                    <img v-for="image, id in leftImages" :id="`verse_image-${image.number}`" :key="id" :src="getUrl(image.url)" :style="`top: ${image.offset}px;`" :alt="image.caption">
                                </div>
                            </div>
                            <div class="text-column">
                                <Paragraph
                                    v-for="paragraph, i in paragraphs"
                                    :key="i"
                                    :paragraph="paragraph"
                                    :has-subheadings="hasSubheadings"
                                    :group-class="groupClass"
                                    :base-scale="0.66"
                                >
                                </Paragraph>
                            </div>
                            <div class="image-column">
                                <div v-if="imagesLoaded">
                                    <img v-for="image, id in rightImages" :id="`verse_image-${image.number}`" :key="id" :src="getUrl(image.url)" :style="`top: ${image.offset}px;`" :alt="image.caption">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="bookData!==null && hasSubheadings===false" class="body__content body__content--chapter">
                    <div id="chapter-text">
                        <div class="chapter-text__with-images">
                            <div class="image-column">
                                <div v-if="imagesLoaded">
                                    <img v-for="image, id in leftImages" :id="`verse_image-${image.number}`" :key="id" :src="getUrl(image.url)" :style="`top: ${image.offset}px;`" :alt="image.caption">
                                </div>
                            </div>
                            <div class="text-column">
                                <div v-for="paragraph, i in paragraphs" :key="i">
                                    <ParagraphDefault
                                        :has-subheadings="hasSubheadings"
                                        :paragraph="paragraph"
                                        :group-class="groupClass"
                                        :base-scale="0.66"
                                        >
                                    </ParagraphDefault>
                                </div>
                            </div>
                            <div class="image-column">
                                <div v-if="imagesLoaded">
                                    <img v-for="image, id in rightImages" :id="`verse_image-${image.number}`" :key="id" :src="getUrl(image.url)" :style="`top: ${image.offset}px;`" :alt="image.caption">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>error</div>
            </div>
        </main>
        <Footer
            :has-back-button="true"
            :has-forward-button="true"
            :has-home-and-up-buttons="true"
            @go-home="$emit('goHome')"
            @go-up-view-level="$emit('goUpViewLevel')"
            @change-chapter="$emit('changeChapter'); resize()"
            @resize="resize"
            >
        </Footer>
    </div>
</template>

<style scoped>
.body {
    position: relative;
    top: 100px;
}
div.body__content--chapter {
    padding: 1rem 0rem 1rem 0rem;
}
@media screen and (orientation: landscape) {
    div.body__content--chapter {
        font-size: 1.5rem;
    }
}
div.verse {
    display: inline;
}
.verse-text {
    font-family: var(--secondary-font-family);
}
.verse-number {
    font-family: var(--primary-font-family);
}
.verse-paragraph {
    padding-bottom: 0.8rem;
}
div#chapter-text {
    max-width: 30ch;
    margin: auto;
    width: 100%;
    height: 100%
}
.chapter-subheading {
    font-size: 1.2rem;
    text-align: center;
    padding: 0.5rem;
    color: #a3a8ac;
}
@media screen and (orientation: landscape) {
    .chapter-subheading {
        font-size: 1.5rem;
    }
}
.chapter-text__with-images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.image-column {
    position: relative;
}
.image-column img {
    position: absolute;
    width: 100%;
}
.text-column {
    font-size: 0.5rem;
}
</style>