<script>
import store from "../Store.js";

export default {
    name: 'Footer',
    emits: [
        'goHome',
        'goUpViewLevel',
        'changeChapter',
        'resize',
    ],
    props: {
      hasBackButton: Boolean,
      hasForwardButton: Boolean,
      hasHomeAndUpButtons: Boolean,
    },
    computed: {
      hasBackButtonComputed() {
        return this.hasBackButton && store.selectedChapter > 1;
      },
      hasForwardButtonComputed() {
        if (store.bookData !== null) {
          return this.hasForwardButton && store.selectedChapter < this.maxChapter;
        }
        return false;
      },
      maxChapter() {
        if (!store.bookData.text) {
          return 0;
        }
        return Object.keys(store.bookData.text).length;
      },
    },
    methods: {
      goBack() {
        if (store.selectedChapter > 1) {
          store.selectedChapter--;
          store.fetchBookData();
        }
      },
      goForward() {
        if (store.selectedChapter < this.maxChapter) {
          store.selectedChapter++;
          store.fetchBookData();
          this.$emit('changeChapter');
        }
      },
    },
}
</script>

<template>
    <footer class="footer footer__content">
        <button v-if="hasHomeAndUpButtons" id="home_button" @click="$emit('goHome')">
            <svg width="100%" height="100%" viewBox="0 0 1800 1800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
                <g id="Layer1">
                    <g transform="matrix(1,0,0,1,12.5199,24.7767)">
                        <path d="M133.85,366.831L389.435,358.318L382.149,991.254L128.075,1003.41L133.85,366.831Z"/>
                    </g>
                    <path d="M485.358,386.13L1632.71,378.892L1634.6,704.349L478.909,678.823L485.358,386.13Z"/>
                    <path d="M485.182,782.261L942.583,784.466L942.259,1495.72L480.642,1494.81L485.182,782.261Z"/>
                    <path d="M1025.47,788.433L1394.8,793.101L1400.11,1162.71L1020.13,1166.45L1025.47,788.433Z"/>
                    <g transform="matrix(1,0,0,1,-1.41658,17.7208)">
                        <path d="M1025.88,1245.56L1503.5,1246.7L1508.58,1373.38L1021.48,1378.28L1025.88,1245.56Z"/>
                    </g>
                </g>
            </svg>
        </button>
        <button v-if="hasBackButtonComputed" id="back_button" @click="goBack">
            <svg width="100%" height="100%" viewBox="0 0 1800 1800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
              <g transform="matrix(6.12323e-17,-1,1,6.12323e-17,80.9692,1768.88)">
                <path d="M867.288,234.752L399.77,727.94L703.22,727.526L728.864,1453.87L1030.95,1460.49L1046.35,733.945L1365.11,735.227L867.288,234.752Z" />
              </g>
            </svg>
        </button>
        <button id="resize_button" @click="$emit('resize')">
            <svg width="100%" height="100%" viewBox="0 0 1800 1800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
              <g transform="matrix(1.29309,0,0,1.29309,-262.744,-263.455)">
                  <path d="M473.7,488.662L476.697,785.193L560.374,785.183L553.054,555.189L773.319,553.951L772.07,482.308L473.7,488.662Z" />
                  <g transform="matrix(-1,1.22465e-16,-1.22465e-16,-1,1792.9,1787.25)">
                      <path d="M473.7,488.662L476.697,785.193L560.374,785.183L553.054,555.189L773.319,553.951L772.07,482.308L473.7,488.662Z" />
                  </g>
                  <g transform="matrix(6.12323e-17,1,-1,6.12323e-17,1797.84,10.2404)">
                      <path d="M475.343,487.577L474.731,774.844L560.463,772.083L558.37,562.673L766.822,558.409L768.412,487.106L475.343,487.577Z" />
                  </g>
                  <g transform="matrix(6.12323e-17,-1,1,6.12323e-17,-10.9039,1790.18)">
                      <path d="M475.343,487.577L474.731,774.844L560.463,772.083L558.37,562.673L766.822,558.409L768.412,487.106L475.343,487.577Z" />
                  </g>
              </g>
            </svg>
        </button>
        <button v-if="hasForwardButtonComputed" id="forward_button" @click="goForward">
            <svg width="100%" height="100%" viewBox="0 0 1800 1800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
              <g transform="matrix(6.12323e-17,1,-1,6.12323e-17,1776.21,4.00444)">
                <path d="M867.288,234.752L399.77,727.94L703.22,727.526L728.864,1453.87L1030.95,1460.49L1046.35,733.945L1365.11,735.227L867.288,234.752Z" />
              </g>
            </svg>
        </button>
        <button v-if="hasHomeAndUpButtons" id="up_button" @click="$emit('goUpViewLevel')">
            <svg width="100%" height="100%" viewBox="0 0 1800 1800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
              <path d="M867.288,234.752L374.473,814.046L700.287,793.897L728.652,1545.47L1049.81,1526.47L1068.83,782.18L1397.74,754.334L867.288,234.752Z"/>
            </svg>
        </button>
    </footer>
</template>

<style scoped>
.footer {
  height: 12vh;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1;
  display: grid;
}
.footer__content {
  grid-template-columns: repeat(12, 1fr);
  background-color: var(--extra-light);
}
.footer__content button {
  height: 100%;
  width: 100%;
  place-self: center;
  font-size: 5em;
  border: none;
  background: none;
  fill: var(--light);
}
@media screen and (orientation: landscape) {
  .footer__content button {
    height: 5rem;
  }
}
.footer__content button:hover {
  fill: var(--dark);
}
#home_button {
  grid-column: 2 / 4;
}
#back_button {
  grid-column: 4 / 6;
}
#resize_button {
  grid-column: 6 / 8;
}
#forward_button {
  grid-column: 8 / 10;
}
#up_button {
  grid-column: 10 / 12;
}
</style>