<script>
export default {
    name: 'SettingsButton',
    emits: ['toggleDrawer'],
    methods: {
        animateSettingsIcon() {
            const settingsIcon = this.$refs.settingsIcon;
            settingsIcon.animate([
                { transform: 'rotateX(0deg)' },
                { transform: 'rotateX(360deg)' },
            ], {
                duration: 250,
                iterations: 1,
            });
        },
    },
}
</script>

<template>
    <button @click="$emit('toggleDrawer'); animateSettingsIcon()" class="settingsIconButton">
        <span ref="settingsIcon" class="material-symbols-outlined settingsIcon">tune</span>
    </button>
</template>

<style scoped>
.settingsIcon {
  font-size: 2rem;
  color: var(--dark);
}
.settingsIconButton {
  position: fixed;
  top: 2vh;
  left: 4vw;
  background-color: #fff;
  z-index: 3;
}
</style>