<script>
export default {
    name: 'RefreshButton',
    methods: {
        animateRefreshIcon() {
            const refreshIcon = this.$refs.refreshIcon;
            refreshIcon.animate([
                { transform: 'rotate(0deg)' },
                { transform: 'rotate(-180deg)' },
            ], {
                duration: 250,
                iterations: 1,
            });
        },
        refreshCache() {
            window.localStorage.clear();
            this.animateRefreshIcon();
        },
    },
}
</script>

<template>
    <button @click="refreshCache" class="refreshIconButton">
        <span ref="refreshIcon" class="material-symbols-outlined refreshIcon">sync</span>
    </button>
</template>

<style scoped>
.refreshIcon {
  font-size: 2rem;
  color: var(--dark);
}
.refreshIconButton {
  position: fixed;
  top: 2vh;
  right: 4vw;
  background-color: #fff;
}
</style>