import { createRouter, createWebHashHistory } from 'vue-router'

import store from "../Store.js";
import BibleView from '../components/BibleView.vue'
import BookView from '../components/BookView.vue'
import ChapterView from '../components/ChapterView.vue'

const TRANSLATION_PARAMETER = ':translation([a-z]{3})'
const BOOK_PARAMETER = ':book([a-z]{3})'
const CHAPTER_PARAMETER = ':chapter(\\d{1,3})'
const DEFAULT_TRANSLATION = 'nwt'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      component: ChapterView,
      name: 'chapter',
      path: `/${TRANSLATION_PARAMETER}/${BOOK_PARAMETER}/${CHAPTER_PARAMETER}`,
      props: true,
    },
    {
      component: BookView,
      name: 'book',
      path: `/${TRANSLATION_PARAMETER}/${BOOK_PARAMETER}`,
      props: true,
    },
    {
      component: BibleView,
      name: 'bible',
      path: `/${TRANSLATION_PARAMETER}`,
      props: true,
    },
    {
      path: '/',
      redirect: () => {
        return { name: 'bible', params: { translation: DEFAULT_TRANSLATION }, props: true }
      }
    },
  ]
})

export default router
