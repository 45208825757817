<script>
export default {
    name: 'PararaphSubheading',
    props: {
        hasSubheadings: Boolean,
        paragraph: Object,
        groupClass: Number,
    },
}
</script>

<template>
    <h2 v-if="paragraph.subheading" class="chapter-subheading">{{ paragraph.subheading }}</h2>
</template>

<style scoped>
.verse-text {
    font-family: var(--secondary-font-family);
}
.verse-number {
    font-family: var(--primary-font-family);
}
.paragraph {
    text-indent: 1rem;
    padding-bottom: 0.5rem;
}
div#chapter-text {
    max-width: 30ch;
    margin: auto;
    width: 100%;
    height: 100%
}
.chapter-subheading {
    font-size: 1.2rem;
    text-align: center;
    padding: 0.5rem;
    color: #a3a8ac;
}
@media screen and (orientation: landscape) {
    .chapter-subheading {
        font-size: 1.5rem;
    }
}
</style>