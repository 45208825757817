<script>
import { RouterView } from 'vue-router';

import store from "./Store.js";
import BibleView from "./components/BibleView.vue";
import BookView from "./components/BookView.vue";
import ChapterView from "./components/ChapterView.vue";
import mixin from "./mixin.js";

export default {
    name: 'App',
    data() {
        return {
            finishedInitialLoad: false,
        }
    },
    computed: {
        metaData() {
            return store.metaData;
        },
        currentViewIndex() {
            return store.currentViewIndex;
        },
        bibleMapName() {
            const storeBibleMapName = store.bibleMapName;
            if (!!storeBibleMapName) {
                return storeBibleMapName;
            } else {
                return 'default';
            }
        },
    },
    methods: {
        goHome() {
          this.updateRoute('bible', { translation: store.translation });
        },
        goUpViewLevel() {
            const urlPath = this.$router.currentRoute.value.path.replace(/^\/|\/$/, '').replace(/^\/|\/$/, '');
            const urlParts = urlPath.split('/');
            switch (urlParts.length) {
                case 1: // Bible view
                    break;
                case 2: // Book view
                    this.goHome();
                    break;
                case 3: // Chapter view
                    this.updateRoute('book', { book: store.selectedBook });
                    break;
            }
        },
    },
    components: {
        RouterView,
        store,
        BibleView,
        BookView,
        ChapterView,
    },
    mixins: [
        mixin,
    ],
    created() {
        store.fetchMetaData();
        this.finishedInitialLoad = true;
    },
}
</script>

<template>
  <div>
    <div v-if="!!finishedInitialLoad">
      <div v-if="metaData!==null">
        <RouterView
          @go-home="goHome"
          @go-up-view-level="goUpViewLevel"
          :name="bibleMapName"
          />
      </div>
      <div v-else>
        <h1>Loading Lumi...</h1>
      </div>
    </div>
  </div>
</template>

<style>
html * { -webkit-text-size-adjust: 100%; }
* {
  margin: 0;
  padding: 0;
  font-family: var(--primary-font-family);
  text-decoration: none;
  color: #000;
  border: none;
}
:root {
  --font: #2f3a55;
  --dark: #7891a4;
  --light: #bad0d7;
  --background: #fff;
  --light-color: #2d84e2;
  --extra-light: #edf1f1;
  --super-light: #e2e8e9;
  --primary-font-family: 'Poppins', sans-serif;
  --secondary-font-family: 'Zilla Slab', serif;
  --group-1-background: #2d84e2;
  --group-1-font: #113681;
  --group-1-number: #2d84e2;
  --group-2-background: #3eb7dc;
  --group-2-font: #115892;
  --group-2-number: #3eb7dc;
  --group-3-background: #5dddda;
  --group-3-font: #2d8096;
  --group-3-number: #5dddda;
  --group-4-background: #2fc26f;
  --group-4-font: #12663a;
  --group-4-number: #2fc26f;
  --group-5-background: #76e166;
  --group-5-font: #2a8d3e;
  --group-5-number: #76e166;
  --group-6-background: #bce54a;
  --group-6-font: #44b53b;
  --group-6-number: #88d835;
  --group-7-background: #f7e124;
  --group-7-font: #ffa500;
  --group-7-number: #f7c907;
  --group-8-background: #feb633;
  --group-8-font: #e06924;
  --group-8-number: #feb633;
  --group-9-background: #f87f47;
  --group-9-font: #c92f12;
  --group-9-number: #f87f47;
  --group-10-background: #f35269;
  --group-10-font: #bf0935;
  --group-10-number: #f35269;
  --group-11-background: #ff9091;
  --group-11-font: #c3313c;
  --group-11-number: #ff9091;
  --group-12-background: #f188b2;
  --group-12-font: #c6256f;
  --group-12-number: #f188b2;
  --group-13-background: #83f3de;
  --group-13-font: #049a9f;
  --group-13-number: #5ee1d3;
}
html {
  font-size: 16px;
}
.app {
  height: 100vh;
  width: 100vw;
}
.body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.link {
  color: var(--dark);
}
.link:hover {
  color: var(--font);
  text-decoration: underline;
}
.link__wrapper {
  padding: 1rem;
}
.grid__item {
  height: 10rem;
  width: 10rem;
  display: flex;
  margin: auto;
}
.grid__item button {
  height: 100%;
  width: 100%;
  place-self: center;
  font-size: 2.5em;
  border-radius: 50%;
}
@media screen and (orientation: landscape) {
  .grid__item button {
    font-size: 2.5rem;
  }
}
.grid__item button:hover {
  color: #fff;
}
.grid__item--group-1 {
	background-color: var(--group-1-background);
	border-color: var(--group-1-background);
	color: var(--group-1-font);
}
.grid__item--group-2 {
	background-color: var(--group-2-background);
	border-color: var(--group-2-background);
	color: var(--group-2-font);
}
.grid__item--group-3 {
	background-color: var(--group-3-background);
	border-color: var(--group-3-background);
	color: var(--group-3-font);
}
.grid__item--group-4 {
	background-color: var(--group-4-background);
	border-color: var(--group-4-background);
	color: var(--group-4-font);
}
.grid__item--group-5 {
	background-color: var(--group-5-background);
	border-color: var(--group-5-background);
	color: var(--group-5-font);
}
.grid__item--group-6 {
	background-color: var(--group-6-background);
	border-color: var(--group-6-background);
	color: var(--group-6-font);
}
.grid__item--group-7 {
	background-color: var(--group-7-background);
	border-color: var(--group-7-background);
	color: var(--group-7-font);
}
.grid__item--group-8 {
	background-color: var(--group-8-background);
	border-color: var(--group-8-background);
	color: var(--group-8-font);
}
.grid__item--group-9 {
	background-color: var(--group-9-background);
	border-color: var(--group-9-background);
	color: var(--group-9-font);
}
.grid__item--group-10 {
	background-color: var(--group-10-background);
	border-color: var(--group-10-background);
	color: var(--group-10-font);
}
.grid__item--group-11 {
	background-color: var(--group-11-background);
	border-color: var(--group-11-background);
	color: var(--group-11-font);
}
.grid__item--group-12 {
	background-color: var(--group-12-background);
	border-color: var(--group-12-background);
	color: var(--group-12-font);
}
.grid__item--group-13 {
	background-color: var(--group-13-background);
	border-color: var(--group-13-background);
	color: var(--group-13-font);
}
.header--group-1 {
	color: var(--group-1-background);
}
.header--group-2 {
	color: var(--group-2-background);
}
.header--group-3 {
	color: var(--group-3-background);
}
.header--group-4 {
	color: var(--group-4-background);
}
.header--group-5 {
	color: var(--group-5-background);
}
.header--group-6 {
	color: var(--group-6-background);
}
.header--group-7 {
	color: var(--group-7-background);
}
.header--group-8 {
	color: var(--group-8-background);
}
.header--group-9 {
	color: var(--group-9-background);
}
.header--group-10 {
	color: var(--group-10-background);
}
.header--group-11 {
	color: var(--group-11-background);
}
.header--group-12 {
	color: var(--group-12-background);
}
.header--group-13 {
	color: var(--group-13-background);
}
.header__number--group-1 {
  color: var(--group-1-font);
  background-color: var(--group-1-background);
}
.header__number--group-2 {
  color: var(--group-2-font);
  background-color: var(--group-2-background);
}
.header__number--group-3 {
  color: var(--group-3-font);
  background-color: var(--group-3-background);
}
.header__number--group-4 {
  color: var(--group-4-font);
  background-color: var(--group-4-background);
}
.header__number--group-5 {
  color: var(--group-5-font);
  background-color: var(--group-5-background);
}
.header__number--group-6 {
  color: var(--group-6-font);
  background-color: var(--group-6-background);
}
.header__number--group-7 {
  color: var(--group-7-font);
  background-color: var(--group-7-background);
}
.header__number--group-8 {
  color: var(--group-8-font);
  background-color: var(--group-8-background);
}
.header__number--group-9 {
  color: var(--group-9-font);
  background-color: var(--group-9-background);
}
.header__number--group-10 {
  color: var(--group-10-font);
  background-color: var(--group-10-background);
}
.header__number--group-11 {
  color: var(--group-11-font);
  background-color: var(--group-11-background);
}
.header__number--group-12 {
  color: var(--group-12-font);
  background-color: var(--group-12-background);
}
.header__number--group-13 {
  color: var(--group-13-font);
  background-color: var(--group-13-background);
}
.verse_number--group-1 {
	color: var(--group-1-number);
}
.verse_number--group-2 {
	color: var(--group-2-number);
}
.verse_number--group-3 {
	color: var(--group-3-number);
}
.verse_number--group-4 {
	color: var(--group-4-number);
}
.verse_number--group-5 {
	color: var(--group-5-number);
}
.verse_number--group-6 {
	color: var(--group-6-number);
}
.verse_number--group-7 {
	color: var(--group-7-number);
}
.verse_number--group-8 {
	color: var(--group-8-number);
}
.verse_number--group-9 {
	color: var(--group-9-number);
}
.verse_number--group-10 {
	color: var(--group-10-number);
}
.verse_number--group-11 {
	color: var(--group-11-number);
}
.verse_number--group-12 {
	color: var(--group-12-number);
}
.verse_number--group-13 {
	color: var(--group-13-number);
}
</style>