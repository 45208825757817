<script>
import store from "../Store.js";
import Footer from "./Footer.vue";
import Header from "./Header.vue";
import BookButton from "./BookButton.vue";
import { nextTick } from "vue";
import mixin from "../mixin.js";

export default {
    name: 'BibleView',
    data() {
        return {
            clickCoordinates: null,
            panZ: null,
        }
    },
    props: {
        name: {
            type: String,
            default: 'default',
        },
        translation: {
            type: String,
        },
    },
    emits: [
        'goHome',
        'goUpViewLevel',
    ],
    components: {
        Footer,
        Header,
        BookButton,
    },
    mixins: [
        mixin,
    ],
    computed: {
        hebrewBooks() {
            return store.metaData[this.name].hb;
        },
        greekBooks() {
            return store.metaData[this.name].gk;
        },
        bibleMapUrl() {
            const imagePath = store.metaData[this.name].image;
            const url = new URL(`${import.meta.env.VITE_MAP_IMAGE_URL}${imagePath}`).href;
            const timestamp = Date.now();
            return `${url}?${timestamp}`;
    },
    },
    methods: {
        openBook(event) {
            const selectedBook = event.target.id.split('-').at(-1);
            store.selectedBook = selectedBook;
            this.updateRoute('book', { book: selectedBook });
        },
        clickBookStart(event) {
            this.clickCoordinates = { x: event.x, y: event.y };
        },
        clickBookEnd(event) {
            const MAX_DISTANCE = 10;
            const dX = event.x - this.clickCoordinates.x;
            const dY = event.y - this.clickCoordinates.y;
            const distance = Math.sqrt(dX ** 2 + dY ** 2);
            if (distance < MAX_DISTANCE) {
                this.openBook(event);
            }
        },
        setUpPanZ(zoomableElement) {
            this.panZ = store.newPanZ(this.$refs.bounding);
            this.panZ.init(zoomableElement);
            // The reset method is called after this as a hack to try to force the zoomable region to center in one motion.
            nextTick();
            if (store.isLandscape()) {
                this.panZ.zoomTo(0.25, 0.5, 0, false, false);
            }
        },
        resize() {
            this.panZ.reset();
            if (store.isLandscape()) {
                this.panZ.zoomTo(0.25);
                this.panZ.panTo(0.5, 1.5, false, true);
            } else {
                // The reset method is called after this as a hack to try to force the zoomable region to center in one motion.
                this.panZ.reset();
                nextTick();
                this.panZ.reset();
            }
        },
    },
    mounted() {
        this.setUpPanZ(this.$refs.zoomable);
    },
}
</script>

<template>
    <div class="app">
        <Header :has-header-title="false" :has-header-number="false">
        </Header>
        <main class="body" ref="bounding" style="max-height: 650px;">
            <div id="body__content--bible" class="body__content" ref="zoomable">
                <img :src="bibleMapUrl"/>
                <div class="body__content--bible">
                    <BookButton v-for="book, id in hebrewBooks" :key="id" @start-click="clickBookStart"
                        @end-click="clickBookEnd" :abbreviation="Object.entries(book)[0][0]"
                        :name="Object.entries(book)[0][1].name" :group="Object.entries(book)[0][1].group"
                        :raw-position="Object.entries(book)[0][1].position" :raw-size="Object.entries(book)[0][1].size">
                    </BookButton>
                    <BookButton v-for="book of greekBooks" :key="book.abbreviation" @start-click="clickBookStart"
                        @end-click="clickBookEnd" :abbreviation="Object.entries(book)[0][0]"
                        :name="Object.entries(book)[0][1].name" :group="Object.entries(book)[0][1].group"
                        :raw-position="Object.entries(book)[0][1].position" :raw-size="Object.entries(book)[0][1].size">
                    </BookButton>
                </div>
            </div>
        </main>
        <Footer :has-back-button="false" :has-forward-button="false" :has-home-and-up-buttons="false"
            @go-home="$emit('goHome')" @go-up-view-level="$emit('goUpViewLevel')" @resize="resize">
        </Footer>
    </div>
</template>

<style scoped>
.body {
    position: relative;
    top: 15vh;
}
.body__content--bible {
    width: 100%;
    height: 100%;
}
img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: -1;
}
</style>