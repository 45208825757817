<script>
export default {
    name: 'VerseNumber',
    props: {
        groupClass: Number,
        number: String,
        isMilestone: Boolean,
        scale: Number,
        baseScale: Number,
    },
    computed: {
        numberScale() {
            if (this.scale < 1) {
                return 1/this.scale;
            } else {
                return 1;
            }
        },
        lineHeight() {
            if (this.numberScale > 2) {
                return "1.5rem";
            } else {
                return "normal";
            }
        },
    },
}
</script>

<template>
    <span v-if="isMilestone" class="verse-number" :class="`verse_number--group-${groupClass}`" :style="`font-size: ${numberScale*baseScale}rem; line-height: ${lineHeight};`">{{ number }}</span>
    <span v-else class="verse-number" :class="`verse_number--group-${groupClass}`" :style="`${baseScale}rem;`">{{ number }}</span>
</template>

<style scoped>
.verse-number {
    font-family: var(--primary-font-family);
}
</style>