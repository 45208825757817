<script>
import VerseNumber from './VerseNumber.vue';
import store from "../Store";

export default {
    name: 'PararaphDefault',
    components: {
        VerseNumber,
    },
    props: {
        hasSubheadings: Boolean,
        paragraph: Array,
        groupClass: Number,
        baseScale: Number,
    },
    data() {
        return {
            key: 0,
            internalScale: 1,
        }
    },
    computed: {
        milestoneNumber() {
            return this.paragraph[0].number;
        },
        externalScale() {
            return store.chapterTextScale;
        },
    },
    watch: {
        'externalScale': function() {
            this.internalScale = this.externalScale;
            this.key += 1;
        }
    },
}
</script>

<template>
    <p class="paragraph">
        <span v-for="verse, verseIndex in paragraph" :key="verseIndex" class="verse-text">
            <VerseNumber
                :id="`verse-${verse.number}`"
                :key="verse.number+key"
                :group-class="groupClass"
                :number="verse.number"
                :is-milestone="verse.number===milestoneNumber"
                :scale="internalScale"
                :base-scale="baseScale"
                />
            &nbsp;{{ verse.text }}&nbsp;
        </span>
    </p>
</template>

<style scoped>
.verse-text {
    font-family: var(--secondary-font-family);
}
.paragraph {
    line-height: normal;
    padding-bottom: 0.5rem;
}
div#chapter-text {
    max-width: 30ch;
    margin: auto;
    width: 100%;
    height: 100%
}
.chapter-subheading {
    font-size: 1.2rem;
    text-align: center;
    padding: 0.5rem;
    color: #a3a8ac;
}
@media screen and (orientation: landscape) {
    .chapter-subheading {
        font-size: 1.5rem;
    }
}
</style>